.form {
  :global .ant-form-item {
    &::before {
      content: unset;
    }
    display: grid;
    grid-template-columns: 20% 50%;
    margin: 0 auto;
    grid-gap: 20px;
  }

  :global .ant-form-item-label {
    text-align: left;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}

.masterSession {
  position: relative;
  left: 212px;

  :global .ant-form-item-control-wrapper {
    span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 52px;
    }
    div {
      margin-right: 10px;
    }
  }
  :global .ant-form-item {
    margin: 10px 0;
    display: grid;
    grid-template-columns: 58% auto;
    grid-gap: 20px;
  }

  :global .ant-form-explain {
    margin: 6px 0;
    margin-left: 50px;
  }
}

.alert {
  margin-bottom: 1rem;
}
