
.tableWrapper {
  :global
  .ant-table-tbody > tr {
    line-height: 20px;
  }
}

.head{
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
  h3{
    font-weight: 400;
    font-size:30px;
  }
}