@import 'utils/index';
@import 'modules/index';

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 100%;
  background: transparent;
  box-sizing: border-box;
  touch-action: manipulation;
}

html,
body {
  width: 100%;
  font-family: $fontFamily;
  background-color: #f1f1f1;
}

img,
embed,
object,
video {
  max-width: 100%;
  height: auto;
}

ul {
  list-style-type: none;
}
