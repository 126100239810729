@import '~styles/utils';
@import '~antd/dist/antd.css'; // DO NOT REMOVE OR REPOSITION

html, body, * {
  font-family: 'Open Sans', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__content {
    position: relative;
    height: 100%;
    flex: 1 1 auto;
    display: flex;
    // padding-top: 75px;

    main {
      flex: 1 1 auto;
      overflow: hidden;
      margin: 27px 20px;
    }

    aside {
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      display: flex;
      flex-direction: column;
      backface-visibility: hidden;
      contain: strict;
      height: 100vh;
      position: sticky;
      top: 0;
      overflow: hidden;
      overflow-y: auto;
      width: 250px;
      background: white;
      @include breakpoint(899) {
        display: none;
      }
    }

    > div {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
    }
  }
}


// ant.d global css

.ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  user-select: none;
}