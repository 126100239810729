.Form {
  &__button {
    font-size: 16px;
    border: none;
    box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);
    padding: 7px;
    min-width: 100px;
    height: auto;
    border-radius: 7px;
  }

  .ant-form-explain {
    padding-top: 5px;
    text-align: left;
  }

  .ant-form-item-with-help {
    margin-bottom: 24px;
  }

  &__input {
    input {
      font-size: 16px;
      height: 42px;
    }
  }
}
