.form {
  :global .ant-form-item {
    &::before {
      content: unset;
    }
    display: grid;
    grid-template-columns: 20% 50%;
    margin: 0 auto;
    grid-gap: 20px;
  }

  :global .ant-form-item-label {
    text-align: left;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}

.alert {
  margin-bottom: 1rem;
}
