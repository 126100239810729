@import '~styles/utils';

.Login {
  background: $primary-color;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
  }

  &__card {
    max-width: 500px;
    width: 100%;
    margin: 0 15px;

    @include breakpoint(500) {
      width: 90%;
    }
  }
}
